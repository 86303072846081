import React, { Fragment } from 'react'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import Testimonial from '../../components/Testimonial'
import { Four, One, Row, RowGroup, Three, Two } from '../../components/Grid'
import OtherProjects from '../../components/OtherProjects'
import ProjectsContext from '../../components/ProjectsContext'
import Image from '../../components/Image'
import Caption from '../../components/Caption'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import Link from '../../components/Link'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/jfrog",
      "name": "jFrog"
    }
  }]
}`

export default () => {
  return (
    <Layout>
    <ProjectsContext.Consumer clientName="jFrog">
      {({ allProjects, currentProject }) => (
        <Fragment>
          <SEO
            path="/projects/jfrog"
            title="Featured AngularJS project for jFrog by 500Tech"
            description="See how we helped jFrog build a new version of Artifactory."
            ld={breadcrumbs}
          />

          <ProjectName>{currentProject.projectName}</ProjectName>
          <Facts {...currentProject} />

          <RowGroup>
            <Row>
              <Three>
                <p>
                  jFrog provides world-class infrastructure for software
                  management and distribution.
                </p>

                <p>
                  Together with jFrog, we worked to rewrite its flagship
                  product, Artifactory, using AngularJS.
                </p>

                <p>
                  <Link to="https://jfrog.com/" white>
                    www.jfrog.com
                  </Link>
                </p>
              </Three>
            </Row>

            <Row>
              <Four>
                <Image path="projects/jfrog/jfrog-1.jpg" />
              </Four>
            </Row>
          </RowGroup>

          <Team team={currentProject.team} />

          <Testimonial />

          <RowGroup>
            <Row>
              <Three>
                <p>
                  The product consists of dozens of screens built from a range
                  of complex UI components, such as trees, editable tables,
                  modals, dropdowns, buttons. It was important to not just
                  implement these screens, but make development of new ones
                  faster, thus cheaper. So we developed a robust and extensible
                  component library.
                </p>
              </Three>
            </Row>

            <Row>
              <Two>
                <Image
                  innerShadow
                  path="projects/jfrog/jfrog-drop-down.png"
                />
                <Caption>Drop-down list</Caption>
              </Two>
              <Two>
                <Image
                  innerShadow
                  path="projects/jfrog/jfrog-two-panes.jpg"
                />
                <Caption>Two-pane selector</Caption>
              </Two>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Our universal table component built for this project is
                  capable of client and server-side pagination, search and
                  sorting, row and column grouping, inline editing and custom
                  cells rendering with links and action menus, some of which
                  would only appear on hover.
                </p>
              </Three>
            </Row>

            <Row>
              <Three>
                <Image innerShadow path="projects/jfrog/jfrog-3.jpg" />
              </Three>
              <One>
                Other features: special scrolling behavior like sticky rows or
                columns or independently scrollable cell content.
              </One>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <One>
                The tree component can have thousands of nodes at each depth, so
                all the contents have to be loaded on demand.
              </One>
              <Three>
                <Image path="projects/jfrog/jfrog-tree.png" />
                <Caption>
                  More actions for every node are available in the context menu
                  on right click
                </Caption>
              </Three>
            </Row>

            <Row>
              <Two>
                <Image
                  innerShadow
                  path="projects/jfrog/jfrog-tree-simple.png"
                />
                <Caption>
                  The tree in a simple mode only shows the current directory
                </Caption>
              </Two>
              <Two>
                <Image
                  innerShadow
                  path="projects/jfrog/jfrog-tree-search.png"
                />
                <Caption>Quick search in the tree</Caption>
              </Two>
            </Row>
          </RowGroup>

          <RowGroup>
            <Row>
              <Three>
                <p>
                  Most pages consist of forms. We have built a dynamic forms
                  system to address sync and async validation, styling, and
                  dependent fields.
                </p>
              </Three>
            </Row>

            <Row>
              <Two>
                <Image
                  innerShadow
                  path="projects/jfrog/jfrog-forms.png"
                />
                <Caption>Form</Caption>
              </Two>
              <Two>
                <Image
                  innerShadow
                  path="projects/jfrog/jfrog-code-editor.png"
                />
                <Caption>Integrated code editor</Caption>
              </Two>
            </Row>
          </RowGroup>

          <Row>
            <Three>
              <Image innerShadow path="projects/jfrog/jfrog-cron.jpg" />
              <Caption>
                If an expression is correct, it displays a human-readable time
                for next run
              </Caption>
            </Three>
            <One>
              Another example of custom component is a cron expression validator
              and parser.
            </One>
          </Row>

          <Row>
            <Three>
              <p>
                All state changes in the application are reflected in the URL.
                If you refresh a page, you get prefilled values in forms, same
                location in the tree, and correct active tab.
              </p>
            </Three>
          </Row>

          <OtherProjects currentProject={currentProject} />
          <ContactForm />
        </Fragment>
      )}
    </ProjectsContext.Consumer>
    </Layout>
  )
}
